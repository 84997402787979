
@font-face {
  font-family: GothamBold;
  src: url(fonts/GothamBold.eot);
  src: url(fonts/GothamBold.eot?#iefix) format("embedded-opentype"),
    url(fonts/GothamBold.woff2) format("woff2"),
    url(fonts/GothamBold.woff) format("woff"),
    url(fonts/GothamBold.ttf) format("truetype"),
    url(fonts/GothamBold.svg) format("svg");
}
@font-face {
  font-family: GothamBook;
  src: url(fonts/Gotham-Book.eot);
  src: url(fonts/Gotham-Book.eot?#iefix) format("embedded-opentype"),
    url(fonts/Gotham-Book.woff2) format("woff2"),
    url(fonts/Gotham-Book.woff) format("woff"),
    url(fonts/Gotham-Book.ttf) format("truetype"),
    url(fonts/Gotham-Book.svg) format("svg");
}
@font-face {
  font-family: GothamMedium;
  src: url(fonts/Gotham-Medium.eot);
  src: url(fonts/Gotham-Medium.eot?#iefix) format("embedded-opentype"),
    url(fonts/Gotham-Medium.woff2) format("woff2"),
    url(fonts/Gotham-Medium.woff) format("woff"),
    url(fonts/Gotham-Medium.ttf) format("truetype"),
    url(fonts/Gotham-Medium.svg) format("svg");
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,'AvenirBlack','AvenirMedium','AvenirHeavy','AvenirLight';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  overflow: hidden;
}




/*============================
    General CSS Starts Here   
=============================*/


:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}


[type=submit], button, html [type=button] {
  -webkit-appearance: none;
}

@media(min-width: 2560px) {
  body {
    font-size: 18px;
  }

  .my_container {
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 1920px) {
  body {
    font-size: 16px;
  }

  .my_container {
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 1919px) {
  body {
    font-size: 14px;
  }

  .my_container {
    max-width: 1298px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 1440px) {
  body {
    font-size: 14px;
  }

  .my_container {
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 1366px) {
  body {
    font-size: 13px;
  }

  .my_container {
    max-width: 1168px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 1280px) {
  body {
    font-size: 12px;
  }

  .my_container {
    max-width: 1094px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 1194px) {
  body {
    font-size: 10px;
  }

  .my_container {
    max-width: 1019px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 834px) {
  body {
    font-size: 11.5px;
  }

  .my_container {
    max-width: 712px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 767px) {
  body {
    font-size: 10px;
  }
}

.img-circle {
  border-radius: 100%;
}


/*============================
  General CSS Ends Here    
============================*/


.d-f {
  display: flex;
}

.jc-sb {
  justify-content: space-between;
}

.fluid {
  max-width: 100%;
    height: auto;
}