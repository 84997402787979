.adminDashboard {
	.sidebar {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		min-height: 100vh !important;
		z-index: 100;
		padding: 48px 0 0;
		box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
	}
	#sidebar-wrapper {
		min-height: 100vh !important;
		width: 100vw;
		margin-left: -1rem;
		-webkit-transition: margin 0.25s ease-out;
		-moz-transition: margin 0.25s ease-out;
		-o-transition: margin 0.25s ease-out;
		transition: margin 0.25s ease-out;
	}
	#sidebar-wrapper .sidebar-heading {
		padding: 0.875rem 1.25rem;
		font-size: 1.2rem;
	}

	#page-content-wrapper {
		min-width: 0;
		width: 100%;
	}

	.pro-sidebar > .pro-sidebar-inner {
		background: #1d1d1d;
		height: 100vh;
	}

	.app main {
		padding: 24px 0;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		padding-top: 0;
	}

	.dashboardDataContainer {
		padding: 0 1em;
		width: 100%;
	}
	.app {
		display: flex;
		position: relative;
		height: 100vh;
	}
	.app main header {
		width: 100%;
		margin-bottom: 40px;
		border-bottom: 1px solid #efefef;
		background: #1d1d1d;
		padding: 1.5em;
		text-transform: uppercase;
		color: #adadad;

		p {
			font-size: 0.875em;
			font-weight: 700;
			letter-spacing: 1px;
		}
	}

	.btn-toggle {
		display: none;
	}
	.app main footer {
		margin-top: auto;
		color: #888;
		text-align: center;
	}

	.button {
		background-color: #ed1c24;
		font-family: Montserrat;
		font-weight: 600;
		font-size: 0.8em;
		letter-spacing: 0.25px;
		line-height: 1.556em;
		color: #ffffff;
		padding: 0.4em 0.6em;
		display: inline-block;
		text-transform: initial;
		transition: 0.3s ease;
		position: relative;
		cursor: pointer;
		z-index: 1;
	}
}

.addJobModel {
	max-width: 70em;
	width: 100%;
	text-align: center;

	.modal-body {
		padding: 2em;
	}

	.floating-label {
		font-size: 1em;
	}

	.rblabel {
		font-weight: 400;
		line-height: 1.556em;
		letter-spacing: 0.2px;
		color: #525252;
	}

	.title {
		font-size: 1.5em;
		letter-spacing: 0.25px;
		line-height: 1.417em;
		font-family: Montserrat;
		font-weight: 700;
		color: #525252;
	}

	.modal-header {
		padding: 0;

		.close {
			top: 25px;
			right: 25px;
			padding: 0;
			position: absolute;
			z-index: 1;
		}
	}
}

@media (max-width: 1023px) {
	.adminDashboard {
		.btn-toggle {
			display: block;
		}
		.pro-sidebar.md {
			position: fixed;
			left: -270px;
		}
		.pro-sidebar.md.toggled {
			left: 0;
		}
		.pro-sidebar.md.toggled .overlay {
			display: block;
		}

		.app {
			main {
				position: relative;

				.btn-toggle {
					position: absolute;
					top: 19px;
					left: 30px;

					svg {
						stroke: #fff;
						fill: #ffff;
						font-size: 1.5em;
					}
				}
				header {
					p {
						margin-bottom: 0;
						font-size: 1.5em;
						font-weight: 700;
						letter-spacing: 0.25px;
						padding-left: 45px;
					}
				}
			}
		}
	}
}

@primary-color: #1DA57A;