main {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    text-align: center;
    /* background-image: url('../../images/Onyx404.png'); */
    /* background-color: #fff; */
}

.pageNotFound h1 {
    color: #034EA2;
    font-size: 18.750em;
    letter-spacing: 0.1em;
    margin: 0.025em 0;
    text-shadow: 0.05em 0.05em 0 rgba(0, 0, 0, .25);
    white-space: nowrap;
      font-family: GothamMedium;
}

.pageNotFound h1 > span {
    animation: spooky 2s alternate infinite linear;
    color: #58585A;
    display: inline-block;
    margin-right: 12px;

}

.pageNotFound h2 {
    color: #58585A;
    margin-bottom: 0.4em;
      font-family: GothamMedium;
    font-size: 2.500em;
}

.pageNotFound p {
    color: #58585A;
    margin-top: 0;
    font-size: 1.875em;
      font-family: GothamMedium;
    margin-bottom: 1em;
}

@media(max-width:834px){
   .pageNotFound h1 {
        color: #034EA2;
        font-size: 12em;
        letter-spacing: 0.1em;
        margin: 0.025em 0;
        text-shadow: 0.05em 0.05em 0 rgba(0, 0, 0, .25);
        white-space: nowrap;
          font-family: GothamMedium;
         
    }
   .pageNotFound h1 > span {
        animation: spooky 2s alternate infinite linear;
        color: #58585A;
        display: inline-block;
        margin-right: 9px;
        
    }
  .pageNotFound  h2 {
        margin-top: 2em;
        font-size: 1.5em;
        margin-bottom: 1em;
    }
    
   .pageNotFound p {
        color: #58585A;
        margin-top: 0;
        font-size: 1.4em;
          font-family: GothamMedium;
        margin-bottom: 4em;
    }
}

@keyframes spooky {
    from {
        transform: translatey(0.15em) scaley(0.95);
   }
    to {
        transform: translatey(-0.15em);
   }
}
