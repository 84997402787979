/* @import '~antd/dist/antd.css'; */

/*============================
    General CSS Starts Here   
=============================*/

/* ::selection {
  color: inherit;
  background-color: inherit;
} */

.us-none {
  user-select: none;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: none;
}

@media (min-width: 2560px) {
  body {
    font-size: 18px;
  }

  .my_container {
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }

  .my_container {
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1919px) {
  body {
    font-size: 14px;
  }

  .my_container {
    max-width: 1298px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1520px) {
  body {
    font-size: 13px;
  }

  .my_container {
    max-width: 1298px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1440px) {
  body {
    font-size: 12px;
  }

  .my_container {
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1366px) {
  body {
    font-size: 12px;
  }

  .my_container {
    max-width: 1168px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 10px;
  }

  .my_container {
    max-width: 1094px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }

  .my_container {
    max-width: 1019px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 834px) {
  body {
    font-size: 11.5px;
  }

  .my_container {
    max-width: 712px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 10px;
  }
}

.img-circle {
  border-radius: 100%;
}

/*============================
    General CSS Ends Here    
  ============================*/

.d-f {
  display: flex;
}

.c-p {
  cursor: pointer;
}

.j-c {
  justify-content: center;
}

.a-c {
  align-items: center;
}
.jc-fe {
  justify-content: flex-end;
}

.f-c {
  flex-direction: column;
}

.marginMiddleHomeContainer {
  margin-top: 7.553em;
}

.p-r {
  position: relative;
}

.h-f {
  height: 100%;
}

.w-f {
  width: 100%;
}

@media (max-width: 1440px) {
  .marginMiddleHomeContainer {
    margin-top: 6.3em;
  }
}

.fluid {
  max-width: 100%;
  height: auto;
}

.w-f {
  width: 100%;
}

.ai-b {
  align-items: baseline;
}

.m-auto {
  margin: auto;
}
.mr-auto {
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}

.d-c {
  display: contents;
}

.w-50 {
  width: 50%;
  padding-right: 2.5%;
}

body .swiper-pagination-bullet {
  opacity: 1;
  background: #034ea2;
  border: 5px solid #ffffff;
  padding: 5px;
  border-radius: 100%;
  /* box-shadow: 0px 0px 0px 0px #bf9e55; */
  box-shadow: none;
  display: inline-block;
  margin: 0 5px;
  transition: 0.3s ease;
}

body .swiper-pagination-bullet-active {
  box-shadow: 0px 0px 0px 2px #ed1c24;
}

@media (max-width: 1280px) {
  .marginMiddleHomeContainer {
    margin-top: 9.3em;
  }
}

@media (max-width: 1023px) {
  .w-50 {
    width: 50%;
    padding-right: 2.5%;
    padding-left: 2.5%;
  }
}
@media (max-width: 834px) {
  .marginMiddleHomeContainer {
    margin-top: 6em;
  }
}

@media (max-width: 1520px) {
  .dots {
    width: 70%;
  }
}
